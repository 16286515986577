
import { defineComponent, computed, ref } from 'vue'
import { concatAddress } from '@/utils/address'
import * as dayJs from '@/utils/dayjs'
import { userCurrency } from '@/use/userCurrency'
import { STATUS_COURIER, ORDER_STATUS, TYPE_SERVICE } from '@/constants/orderEnum'
import { ORDER_PRINT_LABEL_STATUS } from '@/constants/shippingEnum'
import { convertToDate, convertToTime, formatDefault } from '@/utils/dayjs'
import { useBillPayment } from '@/use/useBillPayment'
import { paymentStore } from '@/pinia/payment/payment.pinia'
import { mapCourierLinkTracking } from '@/utils/courier'
import DialogWarning from '@/components/order/DialogWarning.vue'
import { useRouter } from 'vue-router'
import { useChat } from '@/use/useChat'
import { usePrint } from '@/use/usePrint'
import { useUserStore } from '@/pinia/user/user.pinia'
import { useMiddleAccount } from '@/use/middleAccount'
import { useContactChannel } from '@/use/order/useContactChannel'
import { ORDER_CONTACT_CHANNEL,} from '@/constants/orderEnum'
import { COURIER } from '@/constants/courier'
import { useText } from '@/use/useText'
const { useTextOrderToParcel } = useText()

export default defineComponent ({
	name: 'OrderInformationDetail',
	components: { DialogWarning },
	props: {
		orderInfoDetail: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	emits: [ 'onConfirmDeleteOrder' ],
	setup(props) {
		const userStore = useUserStore()
		const { currencyMoneyDigits } = userCurrency()
		const { isDealerType } = useMiddleAccount()

		const enableInsertTaxIdOrder = computed(() => userStore.permission?.settingConfig?.enableInsertTaxIdOrder)

		const isTransfer = computed((): boolean => {
			if(props.orderInfoDetail.payment?.channel === 'TRANSFER') return true
			return false
		})
		const shouldShowReason = computed(() => {
			return (
				props.orderInfoDetail?.reasonDeliveryFailed?.reason &&
				props.orderInfoDetail?.status === 'SENT' &&
				props.orderInfoDetail?.isDeliveryFailed
			)
		})

		const { channels } = useContactChannel()
		const router = useRouter()
		const payment = paymentStore()
		const toPath = ref<string>('')
		const { toPathChat } = useChat()
		const subTxtWarning = ref<string>('')
		const isWarning = ref<boolean>(false)
		const txtWarning = ref<string>('กรุณาเลือกสินค้า')
		const { onOpenDialogBillPaymentBtnClose } = useBillPayment()
		const { mapCourierName, isCourierBlockEditOrder } = usePrint()
		const isBlockBillPayment = computed(() => payment.isBlockBillPayment)
		const previewImageList = computed(() => 
			props
				.orderInfoDetail
				.payment
				.transferInfo
				.slipImage
				.map((slip:any) => {
					if (slip?.url) {
						return slip?.url
					}
					return slip // old string
				})
		)
		const isAddressEmpty = computed((): boolean => {
			if(!props.orderInfoDetail.shipment) return false
			return props.orderInfoDetail?.shipment?.desc1 && props.orderInfoDetail?.shipment?.subDistrictName && props.orderInfoDetail?.shipment?.districtName && props.orderInfoDetail?.shipment?.provinceName && props.orderInfoDetail?.shipment?.zipCode
		})

		const isHideBtEditOrder = computed((): boolean =>{
			return props.orderInfoDetail?.status === 'DRAFT' || props.orderInfoDetail?.status === 'PREPARE_SEND' || props.orderInfoDetail?.status === 'PROCESS'
		})

		const isDraft = computed(() => {
			return props.orderInfoDetail?.status === 'DRAFT'
		})

		const onConfirmWarningOrder = () => {
			subTxtWarning.value = ''
		}

		const getChannelIcon = computed(() => {
			return (channelKey: string) => {
				const matchingChannel = channels.value.find((channel: { key: string }) => channel.key === channelKey)
				return matchingChannel ? matchingChannel.icon : null
			}
		})

		const onCheckEditOrder = () => {
			const isBlock = isCourierBlockEditOrder(props.orderInfoDetail?.courier?._id, props.orderInfoDetail?.printLabelStatus)
			if(!isDraft.value && isBlock || (!isDraft.value && props.orderInfoDetail.courier?.name === COURIER.SPX)) {
				isWarning.value = true
				const textWarning = mapCourierName(props.orderInfoDetail?.courier?._id)
				txtWarning.value = `ออเดอร์ "${textWarning}" ไม่สามารถแก้ไขข้อมูลได้`
				subTxtWarning.value = 'กรุณายกเลิกรายการ และสร้างออเดอร์ใหม่อีกครั้ง'
				toPath.value = '/order'
			}else {
				toPath.value = '/order/edit'
				router.push({ path: '/order/edit', query: {orderID: props.orderInfoDetail._id} })
			}
		}

		const reduceTextCreatedBy = (name: string) => {
			if (name?.length > 8) {
				return `${name.slice(0, 8)}...`
			}
			return name
		}

		const isBtnClaimOrder = computed((): boolean => {
			return props.orderInfoDetail.status === ORDER_STATUS.SENT || props.orderInfoDetail.status === ORDER_STATUS.COMPLETED || props.orderInfoDetail.status === ORDER_STATUS.REJECT
		})

		return {
			dayJs,
			isTransfer,
			STATUS_COURIER,
			isAddressEmpty,
			isHideBtEditOrder,
			isBlockBillPayment,
			ORDER_PRINT_LABEL_STATUS,
			onCheckEditOrder,
			isWarning,
			txtWarning,
			subTxtWarning,
			toPath,
			isBtnClaimOrder,
			shouldShowReason,
			ORDER_STATUS,
			onOpenDialogBillPaymentBtnClose,
			concatAddress,
			currencyMoneyDigits,
			convertToDate,
			convertToTime,
			mapCourierLinkTracking,
			onConfirmWarningOrder,
			toPathChat,
			reduceTextCreatedBy,
			formatDefault,
			previewImageList,
			enableInsertTaxIdOrder,
			TYPE_SERVICE,
			isDealerType,
			getChannelIcon,
			ORDER_CONTACT_CHANNEL,
			useTextOrderToParcel
		}
	}
})
